import React from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import {
  TextLink,
  FormLabel,
  FormControl,
  EntityList,
  ValidationMessage,
  MenuSectionTitle,
  MenuItem,
} from '@contentful/f36-components';
import { PageIcon } from '@contentful/f36-icons';
import loGet from 'lodash.get';
import { getFieldValue } from '../../../utils/contentful';

interface Props {
  value: object | null;
  handleFieldChange: Function;
  logActivity: Function;
  sdk: EditorExtensionSDK;
}

interface State {
  selectedTranslator: object | null;
  error: string;
}

export default class TranslatorField extends React.Component<Props, State> {
  state: State = {
    selectedTranslator: null,
    error: '',
  };
  shouldLoadDefault = false;

  constructor(props: Readonly<Props>) {
    super(props);
    this.shouldLoadDefault = !loGet(this.props.value, 'sys.id');
    //@ts-ignore
    const defaultTranslator = this.props.sdk.parameters.installation.defaultTranslator;
    const translatorId =
      defaultTranslator && this.shouldLoadDefault
        ? defaultTranslator
        : loGet(this.props.value, 'sys.id');
    if (translatorId) {
      this.props.sdk.cma.entry.get({ entryId: translatorId }).then((selectedTranslator) => {
        this.setState({ selectedTranslator });
        if (defaultTranslator && this.shouldLoadDefault) {
          this.setTranslator(selectedTranslator);
        }
      });
    }
  }

  setTranslator = async (selectedTranslator: any) => {
    const locale = this.props.sdk.locales.default;
    if (loGet(selectedTranslator, `fields.translatorInfo.${locale}.isAuthenticated`)) {
      this.setState(() => {
        return {
          selectedTranslator,
          error: '',
        };
      });
      this.props.handleFieldChange('translator', {
        sys: {
          id: selectedTranslator.sys.id,
          linkType: selectedTranslator.sys.type,
          type: 'Link',
        },
			});
			this.props.logActivity({
				level: 'INFO',
				event: `Translator field changed`,
				action: `Translator set to [${loGet(selectedTranslator, `fields.name.${locale}`)}]`,
			});
    } else {
      this.setState(
        {
          error: 'Selected translator is not activated',
          selectedTranslator: null,
        },
        () => {
          this.props.handleFieldChange('translator', null);
        },
      );
    }
  };

  openEntrySelector = async (e: any) => {
    if (e.target.getAttribute('data-test-id') === null) {
      let selectedEntry: any = await this.props.sdk.dialogs.selectSingleEntry({
        //@ts-ignore
        contentTypes: [this.props.sdk.parameters.installation.translatorModelId],
      });
      if (selectedEntry) {
        //@ts-ignore
        const selectedTranslator = await this.props.sdk.cma.entry.get({
          entryId: selectedEntry.sys.id,
        });
        this.setTranslator(selectedTranslator);
      }
    }
  };

  render() {
    return (
      <FormControl isRequired>
        <FormLabel htmlFor="translator">{'Translator'}</FormLabel>

        {this.state.selectedTranslator === null && (
          <TextLink
            testId={'translator'}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
            icon={<PageIcon />}
            onClick={this.openEntrySelector}
          >
            Select a Translator
          </TextLink>
        )}
        {this.state.selectedTranslator !== null && (
          <EntityList>
            <EntityList.Item
              title={'Translator'}
              description={getFieldValue(
                this.state.selectedTranslator,
                'name',
                this.props.sdk.locales.default,
              )}
              withThumbnail={false}
              onClick={this.openEntrySelector}
              actions={[
                <MenuSectionTitle key="title">Actions</MenuSectionTitle>,
                <MenuItem
                  key="remove"
                  onClick={() => {
                    this.setState({ selectedTranslator: null }, () => {
                      this.props.handleFieldChange('translator', null);
                    });
                  }}
                >
                  Remove
                </MenuItem>,
              ]}
            />
          </EntityList>
        )}
        {this.state.error && <ValidationMessage>{this.state.error}</ValidationMessage>}
      </FormControl>
    );
  }
}
