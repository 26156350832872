import React from 'react';
import { FormControl, TextInput } from '@contentful/f36-components';

interface Props {
  value: string;
  handleFieldChange: Function;
  logActivity: Function;
}

export default class TranslationNameField extends React.Component<Props> {
  render() {
    return (
      <FormControl isRequired>
        <FormControl.Label>Name</FormControl.Label>
        <TextInput
          name="nameInput"
          id="nameInput"
          value={this.props.value}
					onChange={(e) => {
						this.props.logActivity({
							level: 'INFO',
							event: `Name field changed`,
							action: `Name set to [${e.target.value}]`
						});
            // @ts-ignore
            this.props.handleFieldChange('translationName', e.target.value as string);
          }}
        />
      </FormControl>
    );
  }
}
