import React from 'react';
import { SidebarExtensionSDK } from '../../extensions-sdk';
import { Button, Note, SectionHeading } from '@contentful/f36-components';
import localClient from '../../utils/localClient';
import loGet from 'lodash.get';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { OPTION_ACCLARO, OPTION_LOCAL } from '../../config/translatorModel';
import {
  checkMimeType,
  createOrderPreferencesData,
  createProgramSelection,
  logActivity,
  slugify,
  transformTranslationEntry,
  uniqueArray,
} from '../../utils/helpers';
import Timeline from './Timeline/Timeline';
import {
  getTranslationProjectStatus,
  STATUS_CHANGED,
  STATUS_IN_PROGRESS,
} from '../../config/translationModel';
import SidebarEntryStatuses from './parts/SidebarEntryStatuses';
import WordCount from './parts/WordCount';
import ActivityManager from '../../../../lambda/src/utils/ActivityManager';
import Ad from '../common/Ad';
import ProjectStatus from './parts/ProjectStatus';
import OrderStatus from './parts/OrderStatus';
import {dateTimeFormat} from "../../utils/helpers";
import SyncAcclaroButton from './parts/SyncAcclaroButton';
import DataManager from '../../utils/data.manager';
import { EntryContentStore } from '../../../../lambda/src/utils/data-manager/data.manager';

interface Props {
  sdk: SidebarExtensionSDK;
}

interface State {
  translator: any;
  loading: boolean;
  translationInfo: any;
  updateTracker: any;
  showSubmitButton: boolean;
  canSubmit: boolean;
  needsUpdate: string[];
  entryContentStore: EntryContentStore;
  invalidLangPairError: any[];
}

export default class TranslationSidebar extends React.Component<Props, State> {
  state: State = {
    translator: null,
    loading: false,
    translationInfo: {},
    updateTracker: new Date(),
    showSubmitButton: true,
    canSubmit: false,
    needsUpdate: [],
    entryContentStore: {},
    invalidLangPairError: []
  };
  timeout: any = null;
  maxAcclaroOrderSize = 250;

  activityManager: ActivityManager;
  private entryId: string;

  constructor(props: Readonly<Props>) {
    super(props);
    this.entryId = this.props.sdk.entry?.getSys().id;
    const translationInfo = this.props.sdk.entry?.fields['translationInfo'].getValue();
    this.activityManager = new ActivityManager(loGet(translationInfo, 'activities', []));
  }

  async componentDidMount() {
    const { sdk } = this.props;
    sdk.window.startAutoResizer();
    await this.handleMount();
  }

  handleMount = async () => {
    this.props.sdk.entry?.onSysChanged(this.handleEntryChange);
    this.props.sdk.entry?.fields['translationInfo'].onValueChanged(this.handleTranslationInfoChange);
    this.props.sdk.entry?.fields['translator'].onValueChanged(this.handleTranslatorChange);
    this.props.sdk.entry?.fields['sourceContent'].onValueChanged(this.handleSourceContentChange);
    this.props.sdk.entry?.fields['targetLanguages'].onValueChanged(this.handleTargetLanguageChange);
    this.props.sdk.entry?.fields['translationName'].onValueChanged(this.updateSubmitButtonStatus);
    this.props.sdk.entry?.fields['localizedReferences'].onValueChanged(
      this.handleLocalizedReferenceChange,
    );
    this.props.sdk.entry?.fields['embeddedReference'].onValueChanged(
      this.handleEmbeddedReferenceChange,
    );
    this.props.sdk.entry?.fields['trackChanges'].onValueChanged(this.handleTrackChangesChange);
    this.props.sdk.entry?.fields['localizationMethod'].onValueChanged(
      this.handleLocalizationMethodChange,
    );
    this.props.sdk.entry?.fields['selectedReferenceFields'].onValueChanged(
      this.handleSelectedReferenceFieldsChange,
    );
    this.props.sdk.entry?.fields['selectedEmbeddedReferences'].onValueChanged(
      this.handleSelectedEmbeddedFieldChange,
    );
    this.props.sdk.entry?.fields['translateSlug'].onValueChanged(
      this.handleTranslateSlugChange
    );
    this.props.sdk.entry?.fields['selectedProgram'].onValueChanged(
      this.handleSelectedProgramFieldChange
    );
    this.props.sdk.entry?.fields['translationNotes'].onValueChanged(
      this.updateSubmitButtonStatus
    )
    this.props.sdk.entry.fields['selectedContent'].onValueChanged(
      this.handleSelectedContentFieldChange
    )
    this.updateSubmitButtonStatus();
    await this.getEntryContentStore();
  };

  /**
   * Right now this is updating the UI in case of any changes in the Entry
   */
  handleEntryChange = () => {
    this.setState({
      updateTracker: new Date(),
    });
  };

  /**
   * Whenever there is a change to translation info we need to update all the related fields as we store a copy of
   * their original value in translation info
   * @param translationInfo
   */
  handleTranslationInfoChange = async (translationInfo: any) => {
    if (translationInfo) {
      this.setState(
        {
          translationInfo,
        },
        async () => {
          await this.handleTranslatorChange();
          await this.handleSourceContentChange();
          await this.handleTargetLanguageChange();
          await this.handleLocalizedReferenceChange();
          await this.handleLocalizationMethodChange();
          await this.handleTrackChangesChange();
          await this.handleSelectedReferenceFieldsChange();
          await this.handleSelectedEmbeddedFieldChange();
          await this.handleEmbeddedReferenceChange();
          await this.handleTranslateSlugChange();
          await this.handleSelectedProgramFieldChange();
          //await this.updateActivity(this.state.translationInfo);
          await this.handleSelectedContentFieldChange();
          await this.getEntryContentStore(true);
        },
      );
    }
  };

  handleSelectedContentFieldChange = async () => {
    const savedSelectedContent = loGet(this.state, 'translationInfo.entry.selectedContent', [])
    const selectedContent = this.props.sdk.entry?.fields?.selectedContent.getValue() || [];
    const entryChanged =
      selectedContent
        .map((sourceContentEntry: any) => sourceContentEntry)
        .some((entryId: string) => {
          return !savedSelectedContent.includes(entryId);
        }) || selectedContent.length != savedSelectedContent.length;
    if (savedSelectedContent.length) {
      if (entryChanged) {
        this.addNeedUpdate('selectedContent');
      } else {
        this.removeNeedUpdate('selectedContent');
      }
    }
    this.updateSubmitButtonStatus();
  }

  getEntryContentStore = async (getFresh = false) => {
    if (getFresh) {
      DataManager.markUpdated(this.entryId);
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      let entryContentStore = await DataManager.get(this.entryId);
      this.setState(
        {
          entryContentStore,
        },
        () => {
          this.updateSubmitButtonStatus();
        },
      );
    }, 1000);
  };

  setNeedsUpdate = (needsUpdate: string[]) => {
    this.setState(
      {
        needsUpdate,
      },
      async () => {
        this.updateSubmitButtonStatus();
      },
    );
  };

  addNeedUpdate = (field: string) => {
    const needsUpdate = [...this.state.needsUpdate, field];
    this.setNeedsUpdate(uniqueArray(needsUpdate));
  };

  removeNeedUpdate = (field: string) => {
    const needsUpdate = this.state.needsUpdate.filter((value: string) => value != field);
    this.setNeedsUpdate(uniqueArray(needsUpdate));
  };

  handleTranslatorChange = async () => {
    let translator = {};
    let translatorId;
    if ((translatorId = loGet(this.props.sdk.entry?.fields?.translator.getValue(), 'sys.id'))) {
      translator = await this.props.sdk.cma.entry.get({ entryId: translatorId });
    }

    const locale = this.props.sdk.locales.default;
    if (loGet(this.state, 'translationInfo.entry.type')) {
      if (
        loGet(translator, `fields.translationService.${locale}`) !=
        loGet(this.state, 'translationInfo.entry.type') ||
        loGet(translator, `fields.apiKey.${locale}`) !=
        loGet(this.state, 'translationInfo.entry.apiKey') ||
        loGet(translator, `fields.sandbox.${locale}`) !=
        loGet(this.state, 'translationInfo.entry.sandbox')
      ) {
        this.addNeedUpdate('translator');
      } else {
        this.removeNeedUpdate('translator');
      }
    }
    this.setState({ translator }, async () => {
      this.updateSubmitButtonStatus();
    });
  };

  handleSourceContentChange = async () => {
    const savedSourceContent = loGet(this.state, 'translationInfo.entry.sourceContent', []).map(
      (savedSourceEntry: any) => savedSourceEntry.sys.id,
    );
    const sourceContent = this.props.sdk.entry?.fields?.sourceContent.getValue() || [];
    const entryChanged =
      sourceContent
        .map((sourceContentEntry: any) => sourceContentEntry.sys.id)
        .some((entryId: string) => {
          return !savedSourceContent.includes(entryId);
        }) || sourceContent.length != savedSourceContent.length;
    if (savedSourceContent.length) {
      if (entryChanged) {
        this.addNeedUpdate('sourceContent');
      } else {
        this.removeNeedUpdate('sourceContent');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleTargetLanguageChange = async () => {
    const savedTargetLanguages = loGet(
      this.state,
      'translationInfo.entry.targetLanguages',
      [],
    ).sort();
    const selectedTargetLanguages = (
      this.props.sdk.entry?.fields?.targetLanguages.getValue() || []
    ).sort();
    const langChanged =
      JSON.stringify(savedTargetLanguages) != JSON.stringify(selectedTargetLanguages);
    if (savedTargetLanguages.length) {
      if (langChanged) {
        this.addNeedUpdate('target');
      } else {
        this.removeNeedUpdate('target');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleLocalizedReferenceChange = async () => {
    const localizedReferences = this.props.sdk.entry?.fields?.localizedReferences.getValue() || false;
    const savedLocalizedReferences = loGet(
      this.state,
      'translationInfo.entry.localizedReferences',
      false,
    );
    if (savedLocalizedReferences != undefined) {
      if (localizedReferences != savedLocalizedReferences) {
        this.addNeedUpdate('locRef');
      } else {
        this.removeNeedUpdate('locRef');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleEmbeddedReferenceChange = async () => {
    const embeddedReference = this.props.sdk.entry?.fields?.embeddedReference.getValue() || false;
    const savedEmbeddedReference = loGet(
      this.state,
      'translationInfo.entry.embeddedReference',
      false,
    );
    if (savedEmbeddedReference != undefined) {
      if (embeddedReference != savedEmbeddedReference) {
        this.addNeedUpdate('embRef');
      } else {
        this.removeNeedUpdate('embRef');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleTranslateSlugChange = async () => {
    const translateSlug = this.props.sdk.entry?.fields?.translateSlug.getValue() || false;
    const translationInfo = this.props.sdk.entry?.fields?.translationInfo.getValue() || {};
    const savedTranslateSlug = translationInfo?.entry?.translateSlug || false;
    if (savedTranslateSlug != undefined) {
      if (translateSlug != savedTranslateSlug) {
        this.addNeedUpdate('slug');
      } else {
        this.removeNeedUpdate('slug');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleLocalizationMethodChange = async () => {
    const localizationMethod = this.props.sdk.entry?.fields?.localizationMethod.getValue();
    const translationInfo = this.props.sdk.entry?.fields?.translationInfo.getValue() || {};
    const savedLocalizationMethod = translationInfo?.entry?.localizationMethod || "";  
    if (savedLocalizationMethod != undefined) {
      if (localizationMethod != savedLocalizationMethod) {
        this.addNeedUpdate('localizationMethod');
      } else {
        this.removeNeedUpdate('localizationMethod');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleTrackChangesChange = async () => {
    const trackChanges = this.props.sdk.entry?.fields?.trackChanges.getValue() || false;
    const savedTrackChanges = loGet(this.state, 'translationInfo.entry.trackChanges', false);
    if (savedTrackChanges != undefined) {
      if (trackChanges != savedTrackChanges) {
        this.addNeedUpdate('trackChanges');
      } else {
        this.removeNeedUpdate('trackChanges');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleSelectedReferenceFieldsChange = async () => {
    const savedReferenceFields = loGet(this.state, 'translationInfo.entry.selectedReferenceFields');
    const savedSelectedReferenceFields = (savedReferenceFields || []).sort();
    const selectedReferenceFields = (
      this.props.sdk.entry?.fields?.selectedReferenceFields.getValue() || []
    ).sort();
    const refChanged =
      JSON.stringify(savedSelectedReferenceFields) != JSON.stringify(selectedReferenceFields);
    if (savedReferenceFields != undefined) {
      if (refChanged) {
        this.addNeedUpdate('savedRef');
      } else {
        this.removeNeedUpdate('savedRef');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleSelectedEmbeddedFieldChange = async () => {
    const savedEmbeddedFields = loGet(
      this.state,
      'translationInfo.entry.selectedEmbeddedReferences',
    );
    const savedSelectedEmbeddedFields = (savedEmbeddedFields || []).sort();
    const selectedEmbeddedFields = (
      this.props.sdk.entry?.fields?.selectedEmbeddedReferences.getValue() || []
    ).sort();
    const change =
      JSON.stringify(savedSelectedEmbeddedFields) != JSON.stringify(selectedEmbeddedFields);
    if (savedEmbeddedFields != undefined) {
      if (change) {
        this.addNeedUpdate('savedEmbed');
      } else {
        this.removeNeedUpdate('savedEmbed');
      }
    }
    this.updateSubmitButtonStatus();
  };

  handleSelectedProgramFieldChange = async () => {
    const savedSelectedProgramField = loGet(
      this.state,
      'translationInfo.entry.selectedProgram'
    );

    const savedSelectedProgram = (savedSelectedProgramField || null);
    const selectedProgram = (this.props.sdk.entry?.fields.selectedProgram.getValue() || null);
    const change = JSON.stringify(savedSelectedProgram) !== JSON.stringify(selectedProgram);
    if (savedSelectedProgramField !== undefined) {
      if (change) {
        this.addNeedUpdate('savedSelectedProgram');
      } else {
        this.removeNeedUpdate('savedSelectedProgram');
      }
    }
    this.updateSubmitButtonStatus();
  }

  handleUpdateEntryLinksChange = async () => {
    const updateEntryLinks = this.props.sdk.entry?.fields?.updateEntryLinks.getValue() || false;
    const savedUpdateEntryLinks = loGet(
      this.state,
      'translationInfo.entry.updateEntryLinks',
      false,
    );
    if (savedUpdateEntryLinks != undefined) {
      if (updateEntryLinks != savedUpdateEntryLinks) {
        this.addNeedUpdate('updateLinks');
      } else {
        this.removeNeedUpdate('updateLinks');
      }
    }
    this.updateSubmitButtonStatus();
  };

  isChangedStatus = () => {
    const entriesForUpdate = this.state.translationInfo.entriesForUpdate || [];
    return (
      this.state.translationInfo.success &&
      (this.state.needsUpdate.length > 0 || entriesForUpdate.length > 0)
    );
  };

  hasEntryContentStoreFiles = () => {
    return this.state?.entryContentStore?.fileData?.length;
  };

  updateSubmitButtonStatus = () => {
    const targetLanguages = this.props.sdk.entry?.fields?.targetLanguages.getValue() || [];
    const sourceContent = this.props.sdk.entry?.fields?.sourceContent.getValue() || [];
    const name = this.props.sdk.entry?.fields?.translationName.getValue();
    const localizationMethod = this.props.sdk.entry?.fields?.localizationMethod.getValue();
    const translationNotes = this.props.sdk.entry?.fields?.translationNotes.getValue();
    this.setState((state) => {
      return {
        canSubmit:
          targetLanguages.length &&
          name &&
          localizationMethod &&
          sourceContent.length &&
          state.translator &&
          translationNotes &&
          !state.loading,
        showSubmitButton:
          !state.translationInfo.success ||
          this.isChangedStatus() ||
          !this.hasEntryContentStoreFiles(),
      };
    });
  };

  getEntryData = () => {
    return {
      sys: {
        id: this.props.sdk.entry.getSys().id,
        version: this.props.sdk.entry.getSys().version,
      },
    };
  };

  isLocal = () => {
    const locale = this.props.sdk.locales.default;
    return loGet(this.state.translator, `fields.translationService.${locale}`) === OPTION_LOCAL;
  };

  isAcclaro = () => {
    const locale = this.props.sdk.locales.default;
    return loGet(this.state.translator, `fields.translationService.${locale}`) === OPTION_ACCLARO;
  };

  updateTranslationInfo = async (data: any) => {
    // Removing unwanted data in case of any api failure to prevent payload size issue
    if (data.entry?.sourceEntries) {
      delete data.entry.sourceEntries;
    }
    if (data.entry?.translationInfo?.entry?.translationInfo) {
      delete data.entry.translationInfo.entry.translationInfo;
    }
    data.hasError = false;
    data.error = {};

    return new Promise((resolve, reject) => {
      this.setState(
        (state) => {
          if (!state.translationInfo.activities && !data['activities']) {
            data['activities'] = [];
          }
          return {
            translationInfo: {
              ...state.translationInfo,
              ...data,
            },
          };
        },
        async () => {
          try {
            const response = await this.props.sdk.entry.fields.translationInfo.setValue(this.state.translationInfo);
            resolve(response);
          } catch (error) {
						this.logActivity({
							level: `ERROR`,
							event: "Error updating translation info:",
							error: JSON.stringify(error)
						});
            reject(error);
          }
        },
      );
    });
  };

  updateActivity = async (translationInfo: any) => {
    const status = this.getStatus();
    if (translationInfo.projectStatus != status.text) {
      await this.updateTranslationInfo({
        projectStatus: status.text,
        activities: this.activityManager.statusChanged(status.text),
      });
    }
  };

  downloadFiles = async () => {
    const { sdk } = this.props;
    const entryId = sdk.entry.getSys().id;
    this.startLoading();
    const zip = new JSZip();
    const fileData: any = this.state.entryContentStore.fileData || [];
    const updatedFileData: any[] = [];
    for (let file of fileData) {
      zip.file(`${file.nameForFile}-${file.target}.json`, JSON.stringify(file, null, 1));
      file.status = STATUS_IN_PROGRESS;
      updatedFileData.push(file);
    }
    const name = this.props.sdk.entry?.fields?.translationName.getValue() || '';
    try {
      zip.generateAsync({ type: 'blob' }).then(async (content) => {
        saveAs(content, `${slugify(name + ' ' + new Date().toUTCString())}` + '.zip');
        this.state.entryContentStore.fileData = updatedFileData;
        await DataManager.store(entryId, this.state.entryContentStore);
        this.getEntryContentStore();
        await this.updateTranslationInfo({
          activities: this.activityManager.downloaded(),
        });
        this.endLoading();
      });
    } catch (err) {
      this.props.sdk.notifier.error('There was error downloading your files. Please try again.');
      this.endLoading();
    }
  };

  upload = () => {
    //@ts-ignore
    document.getElementById('select-file').click();
  };

  processSubmit = async () => {
    try {
      const data = await transformTranslationEntry(this.props.sdk, this.state.translator);
      await this.props.sdk.cma.entry
        .get({ entryId: this.props.sdk.entry.getSys().id })
        .then((entry) => {
          this.props.sdk.cma.entry.publish({ entryId: entry.sys.id }, entry);
        });
      const response: any = await localClient.createTranslation(data);
      if (response.success) {
        delete data.translationInfo; // remove to prevent duplication
        delete data.sourceEntries; // remove to prevent duplication
        await this.updateTranslationInfo({
          ...response.data,
          entry: data,
          showDetailsTab: false,
          activities: response?.data?.isNewOrder ? this.activityManager.created() : '',
        });
        await this.submissionSuccessful();
        this.setState({ needsUpdate: [] });
      } else {
        await this.props.sdk.cma.entry.unpublish({ entryId: this.props.sdk.entry.getSys().id });
        await this.updateTranslationInfo({
          ...response.data,
          entry: data,
          showDetailsTab: false,
          activities: this.activityManager.retrySubmission(),
        });
        await this.submissionUnsuccessful(response.data);
        this.endLoading();
      }
    } catch (e) {
			this.logActivity({
				level: `ERROR`,
				event: "Process submit",
				error: JSON.stringify(e)
			});
      this.handleSubmissionError(e);
      this.endLoading();
    }
  };

  handleSubmissionError = (e: any) => {
    if (e.message == 'too_large') {
      this.props.sdk.notifier.error(
        'The size of the project is larger than what we can handle. Please reduce the number of entries or languages selected.',
      );
    } else if (e.message == 'too_large_acclaro') {
      this.props.sdk.dialogs.openAlert({
        title: 'Order exceeds size limit',
        message: `Your order exceeds the maximum of ${this.maxAcclaroOrderSize
          } deliverable translation files by approximately ${e.size - this.maxAcclaroOrderSize
          } files. Please remove some source entries or target languages from this order and try again. You can email support@acclaro.com or contact your project manager for additional assistance.`,
        confirmLabel: 'OK',
      });
    } else {
      this.props.sdk.notifier.error(
        'Unable to process your request. Check submission and try again.',
      );
    }
  };

  handleSubmit = async () => {
    this.startLoading();
    this.props.sdk.notifier.success('Submitting...');
    await this.processSubmit();
  };

  /**
   * Method for handling tasks related to successful submission
   */
  submissionSuccessful = async () => {
    if (this.isAcclaro()) {
      const submittingToAcclaro = loGet(
        this.state,
        'translationInfo.acclaroStatus.submitting',
        false,
      );
      this.handleAcclaroSuccessfulSync(submittingToAcclaro);
    } else {
      this.props.sdk.notifier.success('Submitted');
      this.endLoading();
    }
  };

  /**
   * Method for handling tasks related to unsuccessful submission
   */
  submissionUnsuccessful = async (data: any) => {
    this.handleAcclaroUnsuccessfulSync(data);
  };

  pushToAcclaro = async () => {
    let acclaroStatus = loGet(this.state, 'translationInfo.acclaroStatus', {});
    acclaroStatus['submitting'] = true;
    await this.updateTranslationInfo({
      lastSyncedAt: dateTimeFormat(),
      acclaroStatus,
    });
    const data = await transformTranslationEntry(this.props.sdk, this.state.translator);
    // Add order preferences data to translationInfo
    if (data.translationInfo) {
      data.translationInfo = await createOrderPreferencesData(this.props.sdk, data);
      data.translationInfo = await createProgramSelection(data);
    }
    const response: any = await localClient.pushToAcclaro(data);
    if (response?.preSubmitActionResponse && response?.preSubmitActionResponse.length > 0) {
      const languageNames = this.props.sdk.locales.names;
      response.preSubmitActionResponse.map((item: any) => {
        if (!this.state.invalidLangPairError.includes(languageNames[item.targetLang])) {
          this.state.invalidLangPairError.push(languageNames[item.targetLang])
        }
      })
      this.setState({
        invalidLangPairError: this.state.invalidLangPairError
      })
    } else {
      this.setState({ invalidLangPairError: [] })
    }
    if (response?.data?.hasError) {
      this.activityManager.retrySubmission();
      this.setState({
        translationInfo: {
          ...response.data,
          showDetailsTab: false,
        },
			});
			this.logActivity({
				level: `ERROR`,
				event: `Push to acclaro`,
				error: JSON.stringify(response.data),
			});
      this.props.sdk.notifier.error(response.data.error?.message ?? 'Order submission failed.');
    } else {
      this.activityManager.submitted();
      this.setState({
        translationInfo: {
          ...response.data,
          showDetailsTab: true,
        },
			});
			this.logActivity({
				level: `INFO`,
				event: `Push to acclaro`,
				action: 'Push to acclaro successful',
			});
      this.props.sdk.notifier.success('Submitted');
    }
    this.endLoading();
  };

  handleAcclaroSuccessfulSync = async (push = false) => {
    await this.updateTranslationInfo({
      lastSyncedAt: dateTimeFormat(),
    });
    if (push) {
      this.pushToAcclaro();
    }
  };

  handleAcclaroUnsuccessfulSync = async (data: any) => {
    if (this.isAcclaro()) {
      let sourceEntryCount =
        loGet(this.state.entryContentStore, `sourceEntries`, []).length *
        loGet(this.state.translationInfo, 'entry.targetLanguages', []).length;
      let fileDataLength = loGet(this.state.entryContentStore, 'fileData', []).length;
      this.logActivity({
				level: `ERROR`,
				event: "Handle acclaro unsuccessful sync",
				error: JSON.stringify(data)
			});
      this.props.sdk.notifier.error(
        `The order was only partially submitted(${fileDataLength} of ${sourceEntryCount}) to Acclaro. Please re-submit.`,
      );
    }
  };

  getStatus = (language: string | null = null) => {
    return getTranslationProjectStatus(
      this.state.translationInfo,
      this.state.entryContentStore,
      this.isChangedStatus.bind(this),
      language,
    );
  };

  getFileData = async (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (e) => {
        //@ts-ignore
        resolve(e.target.result);
      };
      reader.onerror = () => {
        reject('Error reading file');
      };
    });
  };

  /**
   * Updates stored JSON object with translated entries
   * @param event
   */
  onFileUpload = async (event: any) => {
    if (!checkMimeType(event)) {
      this.props.sdk.notifier.error(
        'Unsupported file type. Only .json and .zip are supported at this time.',
      );
      return false;
    }

    try {
      this.startLoading();
      const file = event.target.files[0];
      const uploadedData: any[] = [];
      if (file.type == 'application/json') {
        const fileData: string = await this.getFileData(file);
        uploadedData.push(JSON.parse(fileData));
      } else {
        const zip = new JSZip();
        const zipData: JSZip = await zip.loadAsync(event.target.files[0]);
        for (const file in zipData.files) {
          const fileData = await zipData.files[file].async('string');
          try {
            if (fileData) {
              uploadedData.push(JSON.parse(fileData));
            }
          } catch (Error) {
            this.logActivity({
							level: `ERROR`,
							event: "Error uploading source files",
							error: JSON.stringify(Error)
						});
          }
        }
      }

      if (!uploadedData.length) {
        this.showUploadError('The file is either empty or does not contain supported files.');
      } else {
        const response = await localClient.uploadFile({
          translationInfo: this.state.translationInfo,
          uploadedData,
        });
        if (response.success) {
          await this.updateTranslationInfo({
            ...response.data,
            activities: this.activityManager.uploaded(file.name),
          });
          let message = 'File uploaded successfully.';
          if (response.skipped) {
            let filesText = response.skipped > 1 ? 'files' : 'file';
            message += ` ${response.skipped} ${filesText} skipped - please check the ${filesText}.`;
					}
					this.logActivity({
						level: `INFO`,
						event: `Files uploaded`,
						action: 'Delivery files uploaded',
					});
          this.props.sdk.notifier.success(message);
				} else {
					this.logActivity({
						level: `ERROR`,
						event: `Files uploaded`,
						error: JSON.stringify(response),
					});
          this.showUploadError('There was an error uploading your file.');
        }
      }
    } catch (e) {
			this.logActivity({
				level: `ERROR`,
				event: `Files uploaded`,
				error: JSON.stringify(e),
			});
      this.showUploadError('There was an error uploading your file.');
    }
    this.endLoading();
  };

  showUploadError = async (message: string) => {
    this.props.sdk.notifier.error(message);
    await this.updateTranslationInfo({
      activities: this.activityManager.fileUploadSubmissionFailed(),
    });
  };

  startLoading = () => {
    this.setState({
      loading: true,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
    });
  };

  syncWithAcclaro = async () => {
    this.startLoading();
    this.props.sdk.notifier.success('Syncing...');
    try {
      const response: any = await localClient.syncAcclaro({
        ids: this.props.sdk.ids,
        translationInfo: this.props.sdk.entry?.fields?.translationInfo.getValue(),
      });
      if (response.success) {
        await this.props.sdk.entry?.fields['selectedProgram'].setValue(response.data.entry.selectedProgram);
        await this.updateTranslationInfo({
          ...response.data,
        });
				await this.handleAcclaroSuccessfulSync();
				this.logActivity({
					level: `INFO`,
					event: `Sync with acclaro`,
					action: 'Sync scuccessful',
				});
        this.props.sdk.notifier.success('Sync successful');
			} else {
				this.logActivity({
					level: `ERROR`,
					event: `Sync with acclaro`,
					error: JSON.stringify(response),
				});
        throw response;
      }
		} catch (e) {
			this.logActivity({
				level: `ERROR`,
				event: `Sync with acclaro`,
				error: JSON.stringify(e),
			});
      this.props.sdk.notifier.error(
        'Unable to process your request. Check submission and try again.',
      );
    }
    this.endLoading();
	};

	logActivity = async (data: any) => {
		const { sdk } = this.props;
		await logActivity(sdk, data);
	}

  render() {
    const fileData = loGet(this.state, 'entryContentStore.fileData', []);
    return (
      <div>
        {this.state.showSubmitButton && (
          <Button
            variant={this.getStatus().text == STATUS_CHANGED ? 'primary' : 'positive'}
            isDisabled={!this.state.canSubmit || this.state.loading}
						onClick={() => {
							this.logActivity({
								level: `INFO`,
								event: `Sidebar button clicked`,
								action: `${this.isChangedStatus() ? 'Update translation project' : 'Submit for translation'}`,
							});
							this.handleSubmit();
						}}
            testId="cf-ui-button"
            type="button"
            isLoading={this.state.loading}
            isFullWidth
          >
            {this.isChangedStatus() ? 'Update translation project' : 'Submit for translation'}
          </Button>
        )}
        {!this.state.showSubmitButton && this.isLocal() && (
          <>
            <Button
              variant="secondary"
							onClick={() => {
								this.logActivity({
									level: `INFO`,
									event: `Sidebar button clicked`,
									action: 'Source files downloaded',
								});
								this.downloadFiles();
							}}
              testId="cf-ui-button"
              type="button"
              isLoading={this.state.loading}
              isDisabled={this.state.loading}
              style={{ marginTop: '5px', marginBottom: '15px' }}
              isFullWidth
            >
              Download source files
            </Button>
            <Button
              variant="secondary"
              isDisabled={this.state.loading}
              onClick={this.upload}
              testId="cf-ui-button"
              type="button"
              isLoading={this.state.loading}
              isFullWidth
            >
              Upload translated files
            </Button>
            <input id="select-file" hidden type="file" onChange={this.onFileUpload} />
          </>
        )}
        {!this.state.showSubmitButton && this.isAcclaro() && (
          <SyncAcclaroButton
            syncWithAcclaro={this.syncWithAcclaro}
            loading={this.state.loading}
            translationInfo={this.state.translationInfo}
            entryContentStore={this.state.entryContentStore}
            onFileUpload={this.onFileUpload}
            upload={this.upload}
            download={this.downloadFiles}
            pushToAcclaro={this.pushToAcclaro}
            sdk={this.props.sdk}
						invalidLangPairError={this.state.invalidLangPairError}
						logActivity={this.logActivity}
          />
        )}
        {this.state.invalidLangPairError.length > 0 && (
          <>
            {this.state.invalidLangPairError.length > 0 && (
              <Note variant={'warning'}>
                Translator responded with invalid language pairs for following locales:
                <ol>
                  {this.state.invalidLangPairError.map((item: string) => {
                    return (
                      <li>{item}</li>
                    )
                  })}
                </ol>
              </Note>
            )}
          </>
        )}
        <OrderStatus sdk={this.props.sdk} isAcclaro={this.isAcclaro()} />
        <ProjectStatus
          sdk={this.props.sdk}
          getStatus={this.getStatus}
          entryContentStore={this.state.entryContentStore}
        />
        <WordCount fileData={fileData} />
        <SidebarEntryStatuses
          translationInfo={this.state.translationInfo}
          entryContentStore={this.state.entryContentStore}
        />
        <Ad sdk={this.props.sdk} />

        <SectionHeading className="mt-2">Activity</SectionHeading>
        <hr className="faded" />
        <Timeline items={this.activityManager.get()} locale={this.props.sdk.locales.default} />
      </div>
    );
  }
}
