import { EditorExtensionSDK, PageExtensionSDK, SidebarExtensionSDK } from '../extensions-sdk';
import loGet from 'lodash.get';
import {
  TranslationConfig,
  ENTRY_ID_SEPARATOR,
} from '../../../lambda/src/services/translation/translation.service';
import { getContentTypeFromEntry, getDisplayField, getEntryStatus } from './contentful';
import { STATUS_IN_PROGRESS } from '../config/translationModel';
import {
  isMediaAsset,
  mediaAsset,
  getFullAssets,
  getMediaSourceContent,
  MEDIA_LINK_TYPE,
  isMediaLinkType,
} from './assetHelpers';
import { JSEncrypt } from 'jsencrypt';
import { getJSEncryptPrivateKey, getJSEncryptPublicKey } from './keys';
import { EntryContentStore } from '../../../lambda/src/utils/data-manager/data.manager';
import DataManager from './data.manager';
import { getSourceContentWithReferences } from './referenceEntryHelper';
import saveAs from 'file-saver';
import localClient from './localClient';

export const REQUEST_LIMIT = 500;
const SEPARATOR = '_';

export type ExtensionSDK = EditorExtensionSDK | SidebarExtensionSDK | PageExtensionSDK;
/**
 * Return full entry data from contentful api
 * @param sdk
 * @param entityIdsList
 */
export const getFullEntities = async (sdk: ExtensionSDK, entityIdsList: { [k: string]: any }) => {
  let fullEntries: any[];
  const FullEntriesList = await getFullEntries(sdk, entityIdsList.entryIds);
  fullEntries = [...FullEntriesList];
  const FullAssetsList = await getFullAssets(sdk, entityIdsList.assetIds);
  fullEntries = [...fullEntries, ...FullAssetsList];
  return fullEntries;
};

/**
 * Return full entry data from contentful api
 * @param sdk
 * @param entryIds
 */
export const getElementURL = (sdk: ExtensionSDK, element: any) => {
  const { space, environment } = sdk.ids;
  let type = isTypeEntry(element.sys.type) ? 'entries' : 'asset';
  return `https://${sdk.hostnames.webapp}/spaces/${space}/environments/${environment}/${type}/${element.sys.id}`;
};

export const getFullEntries = async (sdk: ExtensionSDK, entryIds: string[], parent: boolean = false) => {
  let fullEntries: any[] = [];
  entryIds = uniqueArray(entryIds);
  const chunk = (array: any[], size: number) => {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
  };
  let chunkedIds = chunk(entryIds, REQUEST_LIMIT);
  for (let chunkIds of chunkedIds) {
    const entryData: any = await sdk.cma.entry.getMany({
      query: {
        'sys.id[in]': chunkIds,
      },
    });
    fullEntries = [...fullEntries, ...entryData.items];
  }
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  if (parent) {
    for (let id of entryIds) {
      try {
        const response = await sdk.cma.entry.references({
          entryId: id,
          include: 10,
        });
        // Collect linked references (both Entries and Assets)
        if (response.includes) {
          if (response.includes.Entry) {
            fullEntries = [...fullEntries, ...response.includes.Entry];
          }
          if (response.includes.Asset) {
            fullEntries = [...fullEntries, ...response.includes.Asset];
          }
        }
        // Delay to avoid triggering rate limits
        await sleep(200);
      } catch (error) {
        console.error(`Error fetching references for entry ${id}:`, error);
        // Handle error appropriately here, possibly retry or log
      }
    }
  }
  return fullEntries;
};

/**
 * This will be used to fetch entries for mutiple puposes like getting all entries or
 * all entries of a specific content type or using specific query.
 *
 * @param sdk KnownSDK
 * @param total Number of entries to fetch in case we want to get only total number of entries
 * @param subQuery the query filters to override existing ones or add new
 * @returns
 */
export const getAllEntries = async (sdk: ExtensionSDK, total: number = 0, subQuery = {}) => {
  const allEntries: any[] = [];
  const baseQuery = {
    limit: total > 0 ? total : REQUEST_LIMIT,
    skip: 0,
  };
  const finalQuery = { ...baseQuery, ...subQuery };
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  let waitTime = 0;
  let retry = false;

  do {
    try {
      await delay(waitTime);
      const response = await sdk.cma.entry.getMany({
        query: finalQuery,
      });
      allEntries.push(...response.items);

      total = total === 0 ? response.total : total;
      finalQuery.skip += finalQuery.limit;
      if (finalQuery.limit < 500) {
        finalQuery.limit = Math.min(REQUEST_LIMIT, finalQuery.limit * 2);
      }
      retry = false;
		} catch (error) {
			logActivity(sdk, {
				level: 'ERROR',
				event: 'getAllEntries',
				error: JSON.stringify(error),
			});
      error = JSON.parse(error.message);
      retry = true;
      if (error.status === 400 && error.message.includes("Response size too big.")) {
        finalQuery.limit = Math.max(1, Math.floor(finalQuery.limit / 2));
      } else if (error.status === 429) {
        waitTime += 500;
      } else {
        retry = false; // Do not retry for unknown errors
      }
    }
  } while (retry || finalQuery.skip < total);

  return allEntries;
}

export const matchesType = (string1: any, string2: any) => {
  return !string1 || string1 === string2;
};

/** returns the basic structure of an entry or asset **/
export const getBaseEntry = (entry: any, allContentTypes: any, sourceLanguage: string, embeddedEntriesIds: any[] = [], sourceContent: any[] = [], referenceFieldMap: any = {}) => {
  let name = getDisplayField(entry, allContentTypes, sourceLanguage);
  return {
    entryId: entry.sys.id,
    fields: {},
    entryName: name,
    entryStatus: getEntryStatus(entry.sys),
    nameForFile: slugify(name) + `-${entry.sys.id.substring(0, 4)}`,
    contentType: getContentTypeFromEntry(entry),
    type: isMediaLinkType(entry.sys.type) ? MEDIA_LINK_TYPE : TYPE_ENTRY,
    isEmbeddedEntry: !sourceContent.includes(entry.sys.id) && embeddedEntriesIds.some((embeddedEntry: any) => embeddedEntry.sys.id === entry.sys.id),
    isLocalized: referenceFieldMap[`${entry.sys.id}@Entry`] !== undefined || (!sourceContent.includes(entry.sys.id) && !embeddedEntriesIds.some((embeddedEntry: any) => embeddedEntry.sys.id === entry.sys.id))
  };
};

/**
 * Converts data into a form which is ready for consumption on the backend
 */
export const transformTranslationEntry = async (
  sdk: EditorExtensionSDK | SidebarExtensionSDK,
  translator: any,
) => {
  // @ts-ignore
  const locale = sdk.locales.default;
  const data: TranslationConfig = {
    id: sdk.entry.getSys().id,
    // @ts-ignore
    ids: sdk.ids,
    name: sdk.entry.fields.translationName.getValue(),
    type: loGet(translator, `fields.translationService.${locale}`),
    apiKey: loGet(translator, `fields.apiKey.${locale}`),
    sandbox: loGet(translator, `fields.sandbox.${locale}`),
    targetLanguages: sdk.entry.fields.targetLanguages.getValue(),
    dueDate: sdk.entry.fields.requestedDueDate.getValue(),
    comments: sdk.entry.fields.translationNotes.getValue(),
    sourceLanguage: sdk.locales.default,
    localizedReferences: sdk.entry.fields.localizedReferences.getValue() || false,
    localizationMethod: sdk.entry.fields.localizationMethod.getValue(),
    trackChanges: sdk.entry.fields.trackChanges.getValue() || false,
    selectedReferenceFields: sdk.entry.fields.selectedReferenceFields.getValue() || [],
    translationInfo: sdk.entry.fields.translationInfo.getValue() || {},
    selectedEmbeddedReferences: sdk.entry.fields.selectedEmbeddedReferences.getValue() || [],
    embeddedReference: sdk.entry.fields.embeddedReference.getValue() || false,
    showLinkedReference: sdk.entry.fields.showLinkedReference.getValue() || false,
    translateSlug: sdk.entry.fields.translateSlug.getValue() || false,
    selectedProgram: sdk.entry.fields.selectedProgram.getValue() || null,
    selectedContent: sdk.entry.fields.selectedContent.getValue() || [],
    excludedContent: sdk.entry.fields.excludedContent.getValue() || []
  }
  let contentType: any;
  if (isMediaAsset(sdk.entry.fields.contentType.getValue())) {
    contentType = mediaAsset;
  } else {
    contentType = await sdk.cma.contentType.get({
      contentTypeId: sdk.entry.fields.contentType.getValue(),
    });
  }
  const allContentTypes = await getContentTypes(sdk);

  let { sourceEntries, referenceFieldMap } = await (isMediaAsset(
    sdk.entry.fields.contentType.getValue(),
  )
    ? getMediaSourceContent(sdk)
    : getUpdatedSourceContent(sdk, data.localizedReferences));
  const spaceName = `${(await sdk.cma.space.get({ spaceId: sdk.ids.space })).name}` || "N/A";
  data['sourceEntries'] = sourceEntries;
  data['submittedEntryIds'] = sourceEntries.map((e: any) => e.entryId);
  data['contentType'] = contentType;
  data['allContentTypes'] = allContentTypes;
  data['sourceContent'] = sdk.entry.fields.sourceContent.getValue();
  data['referenceFieldMap'] = referenceFieldMap;
  data['spaceName'] = spaceName;

  return data;
};

export const getUpdatedSourceContent = async (sdk: any, localizedReferences: any) => {
  let { sourceEntries, referenceFieldMap, localisedReferences, linkedContentTypes } = await getSourceContentWithReferences(sdk, localizedReferences);

  const excludedContent = await sdk.entry.fields.excludedContent.getValue() || [];
  if (excludedContent.length > 0) {
    sourceEntries = sourceEntries.filter((entry: any) => !excludedContent.includes(entry.entryId))
    return { sourceEntries, referenceFieldMap };
  }
  return { sourceEntries, referenceFieldMap }
}

export const checkMimeType = (event: any) => {
  //getting file object
  const files = event.target.files;
  //define message container
  let err = '';
  // list allow mime type
  const types = [
    'application/zip',
    'application/json',
    'application/x-zip-compressed',
    'application/x-compressed',
    'multipart/x-zip',
  ];
  // loop access array
  for (const file of files) {
    // compare file type find doesn't match
    if (types.every((type) => file.type !== type)) {
      // create error message and assign to container
      err += file.type + ' is not a supported format\n';
    }
  }

  if (err !== '') {
    // if message not same old that mean has error
    event.target.value = null; // discard selected file
    console.log(err);
    return false;
  }
  return true;
};

export const slugify = (text: string) => {
  text = text || '';
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .substr(0, 75);
};

/** Download an entry as JSON **/
export const downloadEntry = async (
  fileEntry: any,
  fileSlug: string,
  sdk: EditorExtensionSDK,
  entryContentStore: EntryContentStore,
) => {
  // trying to keep content to the last
  const content = fileEntry.content;
  let downloadableEntry: { [any: string]: string } = {};
  for (let key in fileEntry) {
    if (key == 'content') {
      continue;
    }
    downloadableEntry[key] = fileEntry[key];
  }
  downloadableEntry.content = content;
  const blob = new Blob([JSON.stringify(downloadableEntry, null, 1)], {
    type: 'text/json;charset=utf-8',
  });
  saveAs(blob, fileSlug);
  const fileData = entryContentStore.fileData || [];
  const fileIndex = fileData.findIndex((storedEntry: any) => {
    return storedEntry.entryId == fileEntry.entryId && storedEntry.target == fileEntry.target;
  });
  fileData[fileIndex].requestedFeedback = fileData[fileIndex].requestedFeedback || [];
  fileData[fileIndex].status = STATUS_IN_PROGRESS;
  entryContentStore.fileData = fileData;
  await DataManager.storeAndRefreshTranslationInfo(sdk, entryContentStore);
};

export const uniqueArray = (arr: any) => {
  return arr.filter(function (item: any, pos: any) {
    return arr.indexOf(item) == pos;
  });
};

/**
 * Encrypts the Translator API key
 */
export const encryptApiKey = (key: string) => {
  let encrypted = '';
  if (key) {
    const encrypt: any = new JSEncrypt();
    encrypt.setPublicKey(getJSEncryptPublicKey());
    encrypted = encrypt.encrypt(key);
  }
  return encrypted;
};

/**
 * Decrypts the Translator API key
 */
export const decryptApiKey = (key: string) => {
  let decrypted = '';
  if (key) {
    const decrypt: any = new JSEncrypt();
    decrypt.setPrivateKey(getJSEncryptPrivateKey());
    decrypted = decrypt.decrypt(key);
  }
  return decrypted;
};
/**
 * Build an entry id based on id and target language combo
 */
export const getEntryId = (entry: { entryId: string; target: string; type: string }) => {
  return (
    entry.entryId +
    (entry.target ? ENTRY_ID_SEPARATOR + entry.target : '') +
    (entry.type ? ENTRY_ID_SEPARATOR + entry.type : '')
  );
};

export const splitEntryId = (entryId: string) => {
  return entryId.split(ENTRY_ID_SEPARATOR);
};

/**
 * returns selection for checkboxes with select all option
 */
export const getSelections = (
  selections: any[],
  entries: any[],
  value: any,
  all: any,
  callback: Function | null = null,
) => {
  callback = callback || getEntryId;
  if (all) {
    if (selections.length === entries.length) {
      selections = [];
    } else {
      // @ts-ignore
      selections = entries.map((entry: any) => callback(entry));
    }
  } else {
    const index = selections.indexOf(value);
    if (index === -1) {
      selections.push(value);
    } else {
      selections.splice(index, 1);
    }
  }
  return selections;
};

export const getContentTypes = async (
  sdk: EditorExtensionSDK | SidebarExtensionSDK | PageExtensionSDK,
) => {
  const contentTypes: any = await getContentTypeChunks(sdk);
  let skip: any = contentTypes.limit;
  let items = contentTypes.items || [];

  if (contentTypes.total > contentTypes.limit) {
    while (skip < contentTypes.total) {
      const contentTypeChunk: any = await getContentTypeChunks(sdk, skip);
      let remainingItems = contentTypeChunk.items || [];
      items.push(...remainingItems);
      skip += remainingItems.length;
    }
  }

  items.push(mediaAsset);
  return items;
};

const getContentTypeChunks = async (
  sdk: EditorExtensionSDK | SidebarExtensionSDK | PageExtensionSDK,
  skip: any = 0,
) => {
  return await sdk.cma.contentType.getMany({ query: { skip: skip } });
};

export const TYPE_ENTRY = 'Entry';

export const isTypeEntry = (type: string) => {
  return !type || type === TYPE_ENTRY;
};

/**
 * Get
 * @param allEntries Array of entry objects or entry identifier strings
 */
export const getEntryAndAssetIds = (allEntries: any) => {
  let entityType: any;
  const getIds = (callback: Function) => {
    return allEntries
      .filter((sEntry: any) => {
        if (typeof sEntry === 'object') {
          const { linkType } = sEntry.entry.sys;
          entityType = linkType;
        } else {
          entityType = splitEntryId(sEntry)[2];
        }
        return callback(entityType);
      })
      .map((sEntry: any) => {
        return typeof sEntry === 'object' ? sEntry.entry.sys.id : splitEntryId(sEntry)[0];
      });
  };

  const entryIds = uniqueArray(getIds(isTypeEntry));
  const assetIds = uniqueArray(getIds(isMediaLinkType));

  return { entryIds: entryIds, assetIds: assetIds };
};

export const getSessionStoreKey = (key: string, sdk: ExtensionSDK, itemsVisible: number) => {
  return (
    key + SEPARATOR + sdk.ids.environment + SEPARATOR + sdk.ids.space + SEPARATOR + itemsVisible
  );
};

export const createProgramSelection = async (data: any) => {
  /**
   * If program selection preferences are already set in the translation configuration,
   * we'll check three conditions to decide whether to send the program to the endpoint.
   * Otherwise, we'll add program selection preferences to the translation config.
   */
  if (data?.translationInfo?.programSelectionPreferences) {
    /**
     * The first condition ensures that we keep 'sendToAcclaro' as true in case of a response failure.
     * The second condition checks if 'sendToAcclaro' should be true when the selected program changes.
     * The third condition checks if there's no program selected, in which case 'sendToAcclaro' should be false.
     */
    data.translationInfo['programSelectionPreferences'].sendToAcclaro =
      data.translationInfo.programSelectionPreferences.sendToAcclaro ||
      (data.translationInfo.programSelectionPreferences.selectedProgram !== data.selectedProgram &&
        data.selectedProgram !== null);
    data.translationInfo.programSelectionPreferences.selectedProgram = data.selectedProgram;
  } else {
    /**
     * If program selection preferences are not set yet,
     * we'll create them with default values based on whether a program is selected or not.
     */
    data.translationInfo['programSelectionPreferences'] = {
      sendToAcclaro: data.selectedProgram === null ? false : true,
      selectedProgram: data.selectedProgram,
    };
  }
  return data.translationInfo;
};

// Check for review file data update if present else create fresh
export const createOrderPreferencesData = async (sdk: any, data: any) => {
  if (data?.translationInfo?.orderPreferences) {
    const prevTranslationInfo = data.translationInfo.orderPreferences;
    data.translationInfo.orderPreferences = await getPreferenceData(sdk, data, prevTranslationInfo);
  } else {
    data.translationInfo['orderPreferences'] = await getPreferenceData(sdk, data);
  }
  return data.translationInfo;
};

const getPreferenceData = async (sdk: any, data: any, oldData: any = {}) => {
  return {
    sendToAcclaro: await shouldSendPrefereneFile(data, oldData),
    content: await getReviewFields(sdk, data),
  };
};

const shouldSendPrefereneFile = async (data: any, oldData: any) => {
  if (!Object.keys(oldData).length || oldData.sendToAcclaro) {
    return true;
  }

  return compareTranslationsInfo(data, oldData.content);
};

// Get the field values to be added in orderPreferences
export const getReviewFields = async (sdk: any, data: any) => {
  return {
    contentType: data.contentType.sys.id,
    localizedReferences: data.localizedReferences,
    selectedReferenceFields: data.selectedReferenceFields,
    showLinkedReference: (await sdk.entry.fields.showLinkedReference.getValue()) || false,
    selectedEmbeddedReferences:
      (await sdk.entry.fields.selectedEmbeddedReferences.getValue()) || [],
  };
};

// Return boolean based on if data has been changed or not
export const compareTranslationsInfo = (
  currentTranslationInfo: any,
  previousTranslationsInfo: any,
) => {
  const keys = Object.keys(previousTranslationsInfo);
  for (const key of keys) {
    let value1 = previousTranslationsInfo[key];
    let value2 = currentTranslationInfo[key];
    if (currentTranslationInfo[key].hasOwnProperty('sys')) {
      value2 = currentTranslationInfo[key].sys.id;
    }
    if (value1 !== undefined && value2 !== undefined) {
      // Recursively compare values
      if (!compareValues(value1, value2)) {
        return true;
      }
    }
  }
  return false;
};

// Compare each value in both the fields
const compareValues = (value1: any, value2: any) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) {
      return false;
    }
    for (let i = 0; i < value1.length; i++) {
      if (!compareValues(value1[i], value2[i])) {
        return false;
      }
    }
    return true; // All elements in both arrays match
  } else {
    return value1 === value2;
  }
};

export const removeValueAndChildren = async (
  value: string,
  selectedRef: string[],
  linkedContentTypes: any,
) => {
  // Remove the value itself
  const index = selectedRef.indexOf(value);
  if (index !== -1) {
    selectedRef.splice(index, 1);
  }

  // Remove its children recursively
  const children = linkedContentTypes[value];
  if (children) {
    for (const child of children) {
      await removeValueAndChildren(child, selectedRef, linkedContentTypes);
    }
  }
};

export const logActivity = async (sdk: any, data: any) => {
	const finalData = {
		...data,
		metadata: {
			...data?.metadata ?? {},
			from: 'frontend'
		},
		entryId: sdk.ids.entry,
		spaceId: sdk.ids.space
	};
	await localClient.logActivity(finalData);
}

export const getAssetURL = (sdk:ExtensionSDK) => {
  return `https://${sdk.hostnames.webapp}/spaces/${sdk.ids.space}/environments/${sdk.ids.environment}/assets/`;
};

export const getEntryWebUrl = (sdk: ExtensionSDK) => {
  return `https://${sdk.hostnames.webapp}/spaces/${sdk.ids.space}/environments/${sdk.ids.environment}/entries/`;
}

/**
 * This method is duplicated on the backend too - since it is not very extensive,
 * we can keep it here for now
 *
 * Loading helpers from backend leads to bundling of backend dependencies in frontend,
 * and some packages are not compatible with frontend (in this case it was the get-stream package as we added
 * a helper which used S3)
 * @param date
 * @param locale
 */
export const dateTimeFormat = (
  date: Date | number = new Date(),
  locale = "en",
) => {
  const dtf = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  return dtf.format(date);
};
