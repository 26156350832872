import React from 'react';
import { Button, FormControl, Menu, Note } from '@contentful/f36-components';
import { ChevronDownIcon } from '@contentful/f36-icons';
import loGet from 'lodash.get';
import ActivityManager from '../../../../../lambda/src/utils/ActivityManager';
import { getPerStatusProjectInfo, STATUS_FAILED } from '../../../config/translationModel';

interface Props {
  syncWithAcclaro: any;
  logActivity: Function;
  download: any;
  upload: any;
  onFileUpload: any;
  loading: boolean;
  translationInfo: any;
  entryContentStore: any;
  pushToAcclaro: any;
  sdk: any;
  invalidLangPairError: string[];
}

interface State {
  isOpen: boolean;
  isLoading: boolean;
}

export default class SyncAcclaroButton extends React.Component<Props, State> {
  state: State = {
    isOpen: false,
    isLoading: false,
  };
  private submittingToAcclaro = false;
  private lastSyncedAt: string = '';
  private activityManager: ActivityManager;

  closeDropDown = () => {
    this.setState(() => {
      return { isOpen: false };
    });
  };

  constructor(props: Readonly<Props>) {
    super(props);
    this.activityManager = new ActivityManager(loGet(this.props.translationInfo, 'activities', []));
  }

  render() {
    this.submittingToAcclaro = loGet(this.props, 'translationInfo.acclaroStatus.submitting', false);
    this.lastSyncedAt = loGet(this.props, 'translationInfo.lastSyncedAt', '');
    return (
      <FormControl>
        <div className={'button-more'}>
          {this.allowRetry() && (
            <Button
              variant="positive"
              onClick={async () => {
								this.setState({ isLoading: true });
								this.props.logActivity({
									level: `INFO`,
									event: `Sidebar button clicked`,
									action: 'Retry order submission',
								});
                await this.props.pushToAcclaro();
                this.setState({ isLoading: false });
              }}
              className="f36-content-width--full"
              testId="cf-ui-button"
              type="button"
              isLoading={this.state.isLoading}
              isDisabled={this.state.isLoading}
              isFullWidth
            >
              Retry submission
            </Button>
          )}
          {!this.allowRetry() && (
            <>
              <Button
                variant="positive"
                style={{ borderRadius: '6px 0 0 6px', minWidth: '80%' }}
								onClick={() => {
									this.props.logActivity({
										level: `INFO`,
										event: `Sidebar button clicked`,
										action: 'Sync with acclaro',
									});
									this.props.syncWithAcclaro();
								}}
                isDisabled={this.props.loading || this.submittingToAcclaro}
                className="f36-content-width--full"
                testId="cf-ui-button"
                isLoading={this.submittingToAcclaro || this.props.loading}
                isFullWidth
              >
                {this.submittingToAcclaro ? 'Processing...' : 'Sync with Acclaro'}
              </Button>
              <Menu
                isAutoalignmentEnabled={true}
                isOpen={this.state.isOpen}
                onClose={() => this.closeDropDown()}
              >
                <Menu.Trigger>
                  <Button
                    className={'acl-button-dropdown'}
                    variant="positive"
                    startIcon={<ChevronDownIcon />}
                    style={{ borderRadius: '0 6px 6px 0' }}
                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                  />
                </Menu.Trigger>
                <Menu.List>
                  <Menu.Item onClick={this.props.download}>Download source files</Menu.Item>
                  <Menu.Item onClick={this.props.upload}>Upload translated files</Menu.Item>
                </Menu.List>
              </Menu>
            </>
          )}
        </div>
        {this.lastSyncedAt.length > 0 && (
          <div className={'translation-sidebar-info'}>LAST SYNCED: {this.lastSyncedAt}</div>
        )}
        {this.allowRetry() && this.props.invalidLangPairError.length === 0 && <Note variant={'warning'}>{this.getErrorMessage()}</Note>}  
        <input id="select-file" hidden type="file" onChange={this.props.onFileUpload} />
      </FormControl>
    );
  }

  allowRetry = () => {
    if (this.tookTooLong() || this.hasFailedEntries()) {
      this.activityManager.retrySubmission();
      return true;
    }
    return false;
  };

  // if 20 mins elapsed allow retry
  retryAvailable = () => {
    // @ts-ignore
    return (new Date() - new Date(this.lastSyncedAt)) / 60000 > 20;
  };

  hasFailedEntries = () => {
    let perStatusInfo = getPerStatusProjectInfo(
      this.props.translationInfo,
      this.props.entryContentStore,
    );
    return !this.submittingToAcclaro && perStatusInfo[STATUS_FAILED]?.count > 0;
  };

  tookTooLong = () => {
    return this.submittingToAcclaro && this.retryAvailable();
  };

  getErrorMessage = () => {
    if (this.tookTooLong()) {
      return 'Error: Project took too long to submit to Acclaro. Please retry submission.';
    } else if (this.hasFailedEntries()) {
      return 'Some files could not be submitted. Please try submitting the project again.';
    }
    return '';
  };
}
