import { ENTRY_ID_SEPARATOR } from '../../../lambda/src/services/translation/translation.service';
import { ENTRY_LEVEL, FIELD_LEVEL } from '../config/translationModel';
import CustomError from '../error/CustomError';
import { EditorExtensionSDK, SidebarExtensionSDK } from '../extensions-sdk';
import { MEDIA_ASSET_FIELDS, MEDIA_LINK_TYPE, isMediaLinkType } from './assetHelpers';
import { getContentTypeFromEntry, getEntryIdWithType, isFieldReference } from './contentful';
import loGet from 'lodash.get';
import {
  TYPE_ENTRY,
  getBaseEntry,
  getContentTypes,
  getEntryAndAssetIds,
  getFullEntities,
  getFullEntries,
  isTypeEntry,
  logActivity,
  matchesType,
} from './helpers';

const allowedEmbeddedBlocks = ['embedded-entry-block', 'embedded-asset-block'];

type LocalisedReferenceList = {
  localisedReferences: object[];
  alreadyAddedTypes: string[];
  alreadyAddedFields: string[];
};

const getFieldIdsFromContentType = (contentType: any): string[] => {
  if (contentType === undefined) {
    return [];
  }
  return contentType.fields
    .filter((field: any) => isFieldReference(field))
    .map((field: any) => field.id);
};

/**
 * Get all the source content with the child entries
 */
export const getSourceContentWithReferences = async (
  sdk: EditorExtensionSDK | SidebarExtensionSDK,
  showLinkedRef: boolean = false,
  selectedRefFields: string[] = [],
  selectedEmbedReferences: string[] = [],
) => {
  const locale = sdk.locales.default;
  let referenceFieldMap: any = {};
  const allContentTypes = await getContentTypes(sdk);
  let selectedContentType = sdk.entry.fields.contentType.getValue();
  const selectedContentTypeObject = allContentTypes.find((aContentType: any) => {
    return aContentType.sys.id === selectedContentType;
  });
  let embeddedEntriesIds: any[] = [];
  let localisedReferenceList: LocalisedReferenceList = {
    localisedReferences: [],
    alreadyAddedTypes: [selectedContentType],
    alreadyAddedFields: getFieldIdsFromContentType(selectedContentTypeObject),
  };
  let linkedContentTypes: any = {};
  if (
    selectedContentType !== undefined &&
    !linkedContentTypes.hasOwnProperty(selectedContentType)
  ) {
    linkedContentTypes[selectedContentType] = localisedReferenceList.alreadyAddedFields;
  }

  const referenceMapper = async (fieldData: any, fieldInfo: any, fullEntry: any) => {
    const fieldEntryIdWithType = getEntryIdWithType(fieldData);
    let referenceFields = referenceFieldMap[fieldEntryIdWithType] || [];
    referenceFields.push({
      upLinkId: loGet(fullEntry, 'sys.id'),
      field: fieldInfo.id,
      type: fieldInfo.type,
      linkType: loGet(fieldData, 'sys.linkType'),
    });
    referenceFieldMap[fieldEntryIdWithType] = referenceFields;
  };

  // Check for presence of embedded entries in rich text and add them to entriesToExplore.
  // After which all embedded entries will be added to source entries and processed
  const processEmbeddedEntries = (contentType: any, fullEntry: any, locale: string) => {
    const embeddedEntryParentField = getEmbeddedEntryParentField(
      contentType,
      selectedEmbeddedReferenceValues,
    );

    if (Object.values(embeddedEntryParentField).length > 0) {
      const embeddedEntryIds = getEmbeddedEntryIds(
        embeddedEntryParentField,
        fullEntry,
        locale,
        selectedEmbeddedReferenceValues,
        sourceContentValue.map((item: any) => { return item.sys.id })
      );
      if (embeddedEntryIds) {
        for (const embeddedEntry of embeddedEntryIds) {
          embeddedEntry.sys.isEmbeddedEntry = true;
          embeddedEntriesIds.push(embeddedEntry);
          // Process embedded entries by adding them to bulkFetchEntries
          bulkFetchEntries.push({ entry: embeddedEntry });
        }
      }
    }
  };

  const processLocalizedReferences = (
    fullEntry: any,
    contentType: any,
    selectedReferenceFieldValue: any,
  ) => {
    if (!sourceReferenceMap[fullEntry.sys.id]) {
      sourceReferenceMap[fullEntry.sys.id] = [];
    }
    for (const field in fullEntry.fields) {
      let fieldValue = loGet(fullEntry, `fields.${field}.${locale}`);

      if (!fieldValue) {
        continue;
      }

      const fieldInfo = contentType.fields.find((fieldDef: any) => {
        return fieldDef.id === field && isFieldReference(fieldDef);
      });

      if (
        !fieldInfo ||
        !selectedReferenceFieldValue ||
        !selectedReferenceFieldValue.includes(fieldInfo.id)
      ) {
        continue;
      }

      if (
        loGet(fieldInfo, 'items.linkType') &&
        [TYPE_ENTRY, MEDIA_LINK_TYPE].includes(loGet(fieldInfo, 'items.linkType'))
      ) {
        for (const fieldEntry of fieldValue) {
          if (fieldEntry) {
            sourceReferenceMap[fullEntry.sys.id].push(fieldEntry.sys.id);
            bulkFetchEntries.push({ entry: fieldEntry });
            referenceMapper(fieldEntry, fieldInfo, fullEntry);
          }
        }
      } else if (
        loGet(fieldInfo, 'linkType') &&
        [TYPE_ENTRY, MEDIA_LINK_TYPE].includes(loGet(fieldInfo, 'linkType'))
      ) {
        if (fieldValue) {
          // If fieldValue is an array, iterate over it
          if (Array.isArray(fieldValue)) {
            for (const fieldEntry of fieldValue) {
              sourceReferenceMap[fullEntry.sys.id].push(fieldEntry.sys.id);
              bulkFetchEntries.push({ entry: fieldEntry });
              referenceMapper(fieldEntry, fieldInfo, fullEntry);
            }
          }
          // If fieldValue is a single object, process it as a single entry
          else if (typeof fieldValue === 'object' && fieldValue.sys) {
            sourceReferenceMap[fullEntry.sys.id].push(fieldValue.sys.id);
            bulkFetchEntries.push({ entry: fieldValue });
            referenceMapper(fieldValue, fieldInfo, fullEntry);
          }
        }
      }
    }
  };

  const collectSourceEntries = (sourceContentEntries: any): any => {
    let sourceEntries: any = [];
    const ids = sourceContentValue.map((item: any) => { return item.sys.id });
    if (sourceContentEntries && sourceContentEntries.length) {
      for (const entry of sourceContentEntries) {
        const sourceEntry: any = getBaseEntry(entry, allContentTypes, locale, embeddedEntriesIds, ids, referenceFieldMap);
        const contentType: any = allContentTypes.find((aContentType: any) => {
          return aContentType.sys.id === getContentTypeFromEntry(entry);
        });

        for (const field in entry.fields) {
          const fieldInfo = contentType.fields.find((fieldDef: any) => {
            return fieldDef.id === field && fieldDef.localized;
          });

          if (
            !fieldInfo &&
            (localizationMethodValue == FIELD_LEVEL)
          ) {
            continue;
          }

          if (isMediaLinkType(entry.sys.type) && !MEDIA_ASSET_FIELDS.includes(field)) {
            continue;
          }

          sourceEntry['fields'][field] = loGet(entry, `fields.${field}.${locale}`);
        }

        if (Object.keys(sourceEntry.fields).length > 0) {
          const embeddedEntry = embeddedEntriesIds.find((embedded) => embedded.sys.id === entry.sys.id);
          if (embeddedEntry && embeddedEntry.sys.embeddedType) {
            sourceEntry.sys.embeddedType = embeddedEntry.sys.embeddedType;
          }
          sourceEntries.push(sourceEntry);
        }
      }
    }

    return sourceEntries;
  };

  const addIfNotPresent = (array: string[], item: string) => {
    if (!array.includes(item)) {
      array.push(item);
    }
  };

  const updateLinkedContentTypes = (contentType: any, field: any) => {
    if (!linkedContentTypes[contentType.sys.id]) {
      linkedContentTypes[contentType.sys.id] = [];
    }
    addIfNotPresent(linkedContentTypes[contentType.sys.id], field.id);
  };

  const filterAndAddNewLocalizedReferenceFields = (contentType: any) => {
    return contentType.fields.filter((field: any) => {
      const isAlreadyAdded = localisedReferenceList.alreadyAddedFields.includes(field.id);
      const isReference = isFieldReference(field);
      if (!isAlreadyAdded && isReference) {
        localisedReferenceList.alreadyAddedFields.push(field.id);
        updateLinkedContentTypes(contentType, field);
        return true;
      }
      return false;
    });
  };

  const collectLocalisedReferences = (contentType: any) => {
    const isContentTypeAlreadyAdded = localisedReferenceList.alreadyAddedTypes.includes(
      contentType.sys.id,
    );
    if (isContentTypeAlreadyAdded) return;

    localisedReferenceList.alreadyAddedTypes.push(contentType.sys.id);
    const newLocalizedReferenceFields = filterAndAddNewLocalizedReferenceFields(contentType);
    localisedReferenceList.localisedReferences.push(...newLocalizedReferenceFields);
  };

  let {
    localizationMethod,
    localizedReferences,
    selectedReferenceFields,
    embeddedReference,
    selectedEmbeddedReferences,
    sourceContent,
  } = sdk.entry.fields;
  let allEntries: any[] = [];
  let missingEntryParent: any = {};
  let sourceReferenceMap: any = {};
  let entriesToExplore = [];
  let sourceContentEntries: any[] = [];
  let uniqueEntries: any = {};
  let sourceContentValue: any[] = sourceContent.getValue() || [];

  let entryIds: any = [];
  let selectedReferenceFieldValue: string[] = [];
  if (selectedRefFields.length > 0) {
    selectedReferenceFieldValue = selectedRefFields;
  } else {
    selectedReferenceFieldValue = selectedReferenceFields.getValue();
  }

  let selectedEmbeddedReferenceValues: any = [];
  if (selectedEmbedReferences.length > 0) {
    selectedEmbeddedReferenceValues = selectedEmbedReferences;
  } else {
    selectedEmbeddedReferenceValues = selectedEmbeddedReferences.getValue();
  }

  for (const entry of sourceContentValue) {
    entriesToExplore.push({ topLevel: true, entry });
    entryIds.push(entry.sys.id);
  }
  let localizationMethodValue = localizationMethod.getValue();

  if (entriesToExplore.length > 0) {
    allEntries = await getFullEntries(sdk, entryIds);
  }

  let bulkFetchEntries: any = [];
  while (entriesToExplore.length > 0) {
    let entryToExplore: any = entriesToExplore.shift();
    let entry = entryToExplore.entry;

    if (!entry) {
      continue;
    }

    let fullEntry = allEntries.find((aEntry: any) => {
      return aEntry.sys.id == entry.sys.id && matchesType(aEntry.sys.type, entry.sys.linkType);
    });
    sourceReferenceMap[entry.sys.id] = [];

    if (!fullEntry) {
      try {
        fullEntry = await (isTypeEntry(entry.sys.linkType)
            ? sdk.cma.entry.get({entryId: entry.sys.id})
            : sdk.cma.asset.get({assetId: entry.sys.id}));
      } catch (error) {
        for (let key in sourceReferenceMap) {
          if (sourceReferenceMap[key].includes(entry.sys.id)) {
            missingEntryParent[key] = allEntries.find((aEntry: any) => {
              return key === aEntry.sys.id;
            });
          }
        }
				logActivity(sdk, {
					level: 'ERROR',
					event: 'Get source content with references',
					error: JSON.stringify(error),
				});
      }
    }

    if (!fullEntry) {
      continue;
    }

    const idWithType = getEntryIdWithType(fullEntry);
    if (uniqueEntries[idWithType]) {
      continue;
    }

    uniqueEntries[idWithType] = true;

    if ('isEmbeddedEntry' in entry.sys) {
      fullEntry.sys.isEmbeddedEntry = true;
    } else {
      fullEntry.sys.isEmbeddedEntry = false;
    }

    sourceContentEntries.push(fullEntry);

    const contentType: any = allContentTypes.find((aContentType: any) => {
      return aContentType.sys.id === getContentTypeFromEntry(fullEntry);
    });

    try {
      collectLocalisedReferences(contentType);
      if (embeddedReference.getValue()) {
        processEmbeddedEntries(contentType, fullEntry, locale);
      }

      if (
          localizedReferences.getValue() &&
          (localizationMethodValue != ENTRY_LEVEL || entryToExplore.topLevel) &&
          showLinkedRef
      ) {
        processLocalizedReferences(fullEntry, contentType, selectedReferenceFieldValue);
      }
    } catch (error) {
      logActivity(sdk, {
				level: 'ERROR',
				event: 'Get source content with references',
				error: JSON.stringify(error),
			});
    }

    if (entriesToExplore.length === 0 && bulkFetchEntries.length > 0) {
      let moreEntries = await getFullEntities(sdk, getEntryAndAssetIds(bulkFetchEntries));
      allEntries = allEntries.concat(moreEntries);
      entriesToExplore = entriesToExplore.concat(bulkFetchEntries);
      bulkFetchEntries = [];
    }
  }
  if (Object.keys(missingEntryParent).length > 0) {
    throw new CustomError('Entry Missing ', missingEntryParent);
  }

  let sourceEntries: any = collectSourceEntries(sourceContentEntries);
  let localisedReferences = localisedReferenceList.localisedReferences;
  localisedReferences = localisedReferences.sort((a: any, b: any) => a.id.localeCompare(b.id));

  return { sourceEntries, referenceFieldMap, localisedReferences, linkedContentTypes, embeddedEntriesIds };
};

// Private functions

const getEmbeddedEntryParentField = (
  contentType: { fields: any[] },
  selectedEmbeddedReferences: [],
): string[] => {
  const fieldsWithEmbeddedBlock = contentType.fields.filter((field) => {
    return (
      field.type == 'RichText' &&
      field.localized &&
      hasEmbeddedBlockValidation.bind(null, selectedEmbeddedReferences)
    );
  });
  return fieldsWithEmbeddedBlock.map((fieldDef) => fieldDef.id);
};

const hasEmbeddedBlockValidation = (fieldDef: any, selectedEmbeddedReferences: []): boolean => {
  return fieldDef.validations.some(
    validationHasEmbeddedBlock.bind(null, selectedEmbeddedReferences),
  );
};

const validationHasEmbeddedBlock = (
  validation: Record<string, any>,
  selectedEmbeddedReferences: string[],
): boolean => {
  return (
    validation.enabledNodeTypes &&
    validation.enabledNodeTypes.some((item: string) => selectedEmbeddedReferences.includes(item))
  );
};

const getEmbeddedEntryIds = (
  fieldIds: string[],
  entry: any,
  locale: string,
  selectedEmbeddedReferences: string[],
  soureContentIds: string[] = []
): any => {
  let embeddedIds: any = [];

  for (const fieldId of fieldIds) {
    const fieldContent = loGet(entry, `fields.${fieldId}.${locale}.content`, []);
    embeddedIds = [...embeddedIds, ...checkRecurrsive(fieldContent, selectedEmbeddedReferences, soureContentIds)];
  }
  return embeddedIds;
};

const checkRecurrsive = (fieldContent: any, selectedEmbeddedReferences: string[], sourceContentIds: string[] = []) => {
  let embeddedIds: any = [];
  for (const contentNode of fieldContent) {
    if (selectedEmbeddedReferences && selectedEmbeddedReferences.includes(contentNode.nodeType)) {
      const embeddedSysObject = contentNode.data.target;
      if (!sourceContentIds.includes(embeddedSysObject.sys.id)) {
        embeddedSysObject.sys.embeddType = contentNode.nodeType;
        embeddedIds.push(embeddedSysObject);
      }
    }
    if ('content' in contentNode && contentNode.content.length > 0) {
      embeddedIds = [
        ...embeddedIds,
        ...checkRecurrsive(contentNode.content, selectedEmbeddedReferences, sourceContentIds),
      ];
    }
  }
  return embeddedIds;
};
